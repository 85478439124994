import dynamic from "next/dynamic";
import {
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemIcon,
  ListItemButton
} from "@mui/material";
import { HistoryRounded as RecentIcon } from "@mui/icons-material";

import { entityMap } from "../Search/Hooks";

const RenderPaperTitle = dynamic(() => import("../Markdown/PaperTitle"));
const PaperSubheader = dynamic(() =>
  import("../Card/Paper").then(module => module.SubHeader)
);

export default function ListItemEntity({ entity, line2 = false, ...props }) {
  const type = entity.__typename?.toLowerCase();

  switch (type) {
    case "recent": {
      var listItemProps = {
        leading: { Icon: RecentIcon },
        line1: entity.recentQuery,
        href: `/search?q=${entity.recentQuery}`
      };
      break;
    }
    case "paper": {
      listItemProps = {
        leading: { Icon: entityMap.paper.Icon },
        line1: <RenderPaperTitle paperTitle={entity.title} />,
        line2: line2 ? (
          <PaperSubheader publisher published org paper={entity} />
        ) : undefined,
        href: `/docs/${entity.publisher}/${entity.paperID}/paper`
      };
      break;
    }

    case "tag": {
      listItemProps = {
        leading: { Icon: entityMap.task.Icon },
        line1: entity.name,
        href: `/search?type=papers&task=${entity.name}`
      };
      break;
    }
    case "model": {
      listItemProps = {
        leading: { Icon: entityMap.model.Icon },
        line1: entity.displayName,
        line2: entity.org,
        href: `/docs/${entity.org}/${entity.displayName}/model`
      };
      break;
    }
    case "video": {
      listItemProps = {
        leading: { Icon: entityMap.video.Icon },
        line1: entity.title
      };
    }
  }

  return <ListItem type={type} {...listItemProps} {...props} />;
}

export const ListItem = ({
  type,
  href,
  line1,
  line2,
  line3,
  sx = {},
  line1Sx = {},
  leading,
  trailing = {},
  lineClamp1 = 2,
  ...props
}) => (
  <ListItemButton
    href={href}
    sx={{
      py: 1,
      px: 2,
      maxHeight: 88,
      height: line3 === undefined ? (line2 === undefined ? 56 : 72) : 88,
      ...sx
    }}
    {...props}
  >
    {leading ? (
      leading.Avatar ? (
        <ListItemAvatar>
          <Avatar
            sx={{
              height: 40,
              width: 40,
              color: "var(--primary-on-container)",
              bgcolor: "var(--primary-container)",
              typography: "titleMd"
            }}
          >
            <leading.Avatar />
          </Avatar>
        </ListItemAvatar>
      ) : leading.img ? (
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={leading.img}
            sx={{
              height: 56,
              width: "auto",
              color: "var(--primary-on-container)",
              bgcolor: "var(--primary-container)",
              typography: "titleMd",
              "& svg": {
                display: "none"
              }
            }}
          >
            <leading.Icon />
          </Avatar>
        </ListItemAvatar>
      ) : leading.videoThumbnail ? (
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={leading.videoThumbnail}
            sx={{
              height: 64,
              width: "auto",
              aspectRatio: "16 / 9",
              color: "var(--primary-on-container)",
              bgcolor: "var(--primary-container)",
              typography: "titleMd"
            }}
          >
            <leading.Icon />
          </Avatar>
        </ListItemAvatar>
      ) : leading.Icon ? (
        <ListItemIcon sx={{ minWidth: "unset" }}>
          <leading.Icon
            sx={{
              width: 24,
              height: 24,
              color: "var(--surface-on-variant)"
            }}
          />
        </ListItemIcon>
      ) : null
    ) : null}
    <ListItemText
      primary={line1}
      secondary={line2}
      slotProps={{
        primary: {
          maxWidth: "85%",
          textTransform: "capitalize",
          typography: { compact: "bodyMd", expanded: "bodyLg" },
          color: "var(--surface-on-color)",
          sx: theme => ({
            ...theme.typography.lineClamp(lineClamp1),
            ...line1Sx
          })
        },
        secondary: {
          variant: "bodyMd",
          color: "var(--surface-on-variant)",
          sx: theme => theme.typography.lineClamp(1)
        }
      }}
      sx={{ pl: leading ? 2 : undefined }}
    />
    {trailing.Icon ? (
      <ListItemIcon sx={{ minWidth: "unset" }}>
        <trailing.Icon
          sx={{ color: "var(--surface-on-variant)", height: 24, width: 24 }}
        />
      </ListItemIcon>
    ) : trailing.supportText ? (
      <ListItemText
        primary={trailing.supportText}
        slotProps={{
          primary: {
            variant: "labelSm",
            color: "var(--surface-on-variant)"
          }
        }}
      />
    ) : null}
  </ListItemButton>
);
