import dynamic from "next/dynamic";
import {
  PlayCircleOutlineRounded as VideoIcon,
  TokenOutlined as TaskIcon
} from "@mui/icons-material";

import { PaperIcon } from "../../Icon/Papers";
import { ModelIcon } from "../../Icon/Model";

const PaperCardSizeSm = dynamic(() =>
  import("../../Card/Paper").then(mod => mod.PaperCardSize25)
);
const PaperCardSize5 = dynamic(() =>
  import("../../Card/Paper").then(mod => mod.PaperCardSize5)
);
const CardVideoSize1 = dynamic(() =>
  import("../../Card/External/Video").then(module => module.CardVideoSize1)
);
const CardVideoSize3 = dynamic(() =>
  import("../../Card/External/Video").then(module => module.CardVideoSize3)
);
const CardModelSm = dynamic(() =>
  import("../../Card/Model").then(module => module.ModelCardSize2)
);
const CardModelSizeLg = dynamic(() =>
  import("../../Card/Model").then(module => module.ModelCardSize4)
);
const CardTaskSize2 = dynamic(() =>
  import("../../Card/Task").then(module => module.TaskSize2)
);
const CardTaskSize4 = dynamic(() =>
  import("../../Card/Task").then(module => module.CardTaskSize4)
);

export const entities = [
  {
    Icon: ModelIcon,
    label: "models",
    href: "models",
    Card: [CardModelSm, CardModelSizeLg],
    entityName: "model",
    query: "searchModels"
  },
  {
    Icon: PaperIcon,
    label: "papers",
    href: "papers",
    capital: "Papers",
    Card: [PaperCardSizeSm, PaperCardSize5],
    entityName: "paper",
    query: "searchPapers"
  },
  {
    Icon: VideoIcon,
    label: "videos",
    href: "videos",
    capital: "Videos",
    Card: [CardVideoSize1, CardVideoSize3],
    entityName: "video",
    query: "searchVideos"
  },
  {
    Icon: TaskIcon,
    label: "tasks",
    href: "tasks",
    capital: "Tasks",
    Card: [CardTaskSize2, CardTaskSize4],
    key: "task",
    entityName: "tag",
    query: "searchTasks"
  }
];

export const entityMap = Object.fromEntries(
  entities.flatMap(entity => [
    [entity.label, entity],
    [entity.key || entity.entityName, entity]
  ])
);
