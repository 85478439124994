import { Divider, Stack, Typography } from "@mui/material";
import {
  GitHub as GithubIcon,
  TerminalRounded as DocsIcon
} from "@mui/icons-material";
import ApiIcon from "@mui-symbols-material/w400/DeployedCodeRounded";

import { useStore, observer } from "../../service/mobx";
import { NavListItem } from "./index";
import ToolTip from "../Tooltip";

function Developers() {
  const { layout } = useStore();

  return (
    <Stack useFlexGap spacing={1}>
      <Typography
        component="p"
        variant="titleSm"
        color="secondary"
        align="center"
        pl={layout.navOpen ? 2 : undefined}
        width={layout.navOpen ? 0 : 63}
      >
        {layout.navOpen ? "Developers" : "Devs"}
      </Typography>
      <ToolTip placement="right" title={layout.navOpen ? "" : "Model API"}>
        <NavListItem
          line1="Model API"
          href="/api"
          leading={{ Icon: ApiIcon }}
        />
      </ToolTip>
      <ToolTip placement="right" title={layout.navOpen ? "" : "Docs"}>
        <NavListItem
          line1="docs"
          target="_blank"
          href="https://docs.bytez.com"
          leading={{ Icon: DocsIcon }}
        />
      </ToolTip>
      <ToolTip placement="right" title={layout.navOpen ? "" : "GitHub"}>
        <NavListItem
          line1="GitHub"
          target="_blank"
          href="https://github.com/Bytez-com/docs"
          leading={{ Icon: GithubIcon }}
        />
      </ToolTip>
      <Divider />
    </Stack>
  );
}

export default observer(Developers);
