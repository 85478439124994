import { Box } from "@mui/material";

const Skeleton = ({
  height = 56,
  width = "100%",
  borderRadius = "md-round"
}) => (
  <Box
    height={height}
    width={width}
    component="span"
    bgcolor="var(--surface-container-low)"
    borderRadius={`var(--shape-${borderRadius})`}
    sx={{ animation: "pulse 4s ease-in-out 1s infinite" }}
  />
);

export default Skeleton;
