import { useEffect, useState } from "react";
import {
  Box,
  Divider,
  List,
  Typography,
  useColorScheme,
  ListItem as GenericListItem,
  Collapse
} from "@mui/material";
import {
  ContentCopyRounded as CopyIcon,
  DarkModeRounded as DarkModeIcon,
  LightModeRounded as LightModeIcon,
  SettingsBrightnessRounded as SystemModeIcon
} from "@mui/icons-material";
import FeedbackIcon from "@mui-symbols-material/w400/ForumRounded";
import useSWR from "swr";

import { useStore, observer } from "../../../service/mobx";
import { AnonymousIcon } from "../../Icon/Anonymous";
import { DiscordIcon } from "../../Icon/Discord";
import { ListItem } from "../../List/ListItem";
import { dialogFeedback } from "../../Dialog/dialogs/appWide/Feedback";
import { fetcherAPIServer } from "../../../service/graph";
import Skeleton from "../../Skeleton";

function AccountMenu() {
  // const { snackbar, user } = useStore();

  return <Component />;
}
const Component = observer(function Component() {
  const { dialog, snackbar, user } = useStore();
  const line1Sx = { textTransform: "unset" };

  return (
    <List
      disablePadding
      dense
      sx={{
        p: 0,
        "& svg": {
          opacity: 0.5
        },
        "& .MuiButtonBase-root:hover svg": {
          opacity: 1
        }
      }}
    >
      <Profile line1Sx={line1Sx} />
      <ListItem
        line1Sx={line1Sx}
        line1="API key"
        line2={user.isAnonymous ? "Login to reveal" : undefined}
        trailing={{ Icon: CopyIcon }}
        onClick={() => {
          if (user.isAnonymous) {
            user.sign.in.google();
          } else {
            navigator.clipboard.writeText(user.profile.key);
            snackbar.notify({ text: "Copied" });
          }
        }}
      />
      <Divider />
      <ListItem
        line1Sx={line1Sx}
        line1="Contact us"
        line2="Send feedback or get help"
        trailing={{ Icon: FeedbackIcon }}
        onClick={() => dialog.configure(dialogFeedback)}
      />
      <ListItem
        line1Sx={line1Sx}
        target="_blank"
        href="https://discord.gg/ZydhEJTd3R"
        line1="Join our community"
        line2="Help us build Bytez"
        trailing={{
          Icon: () => <DiscordIcon sx={{ color: "var(--tertiary-color)" }} />
        }}
      />
      <Divider />
      <ChangeTheme line1Sx={line1Sx} />
    </List>
  );
});

export default observer(AccountMenu);

const Profile = observer(function LogInOrOut({ line1Sx }) {
  const [customerPortalUrl, setCustomerPortalUrl] = useState();
  const [upgradeUrl, setUrl] = useState();
  const { analytics, user, utilities } = useStore();
  const { premium } = analytics.meters;
  const { data } = useSWR(
    premium || user.loaded === false || user.customerId === undefined
      ? null
      : "models/v2/list/models?count=1",
    fetcherAPIServer
  );

  useEffect(() => {
    if (user.loaded && user.isAnonymous === false && user.syncedWithStripe) {
      utilities
        .stripe("PUT", "{}")
        .then(({ url }) => setCustomerPortalUrl(url));

      if (premium === false) {
        utilities
          .stripe("PUT", JSON.stringify({ upgrade: true }))
          .then(({ url }) => setUrl(url));
      }
    }
  }, [utilities, user, user.isAnonymous, user.syncedWithStripe, premium]);

  return (
    <>
      <ListItem
        line1Sx={line1Sx}
        disableRipple
        line1={user.isAnonymous ? "Anonymous" : user.session.displayName}
        line2={
          user.loaded && (user.syncedWithStripe || user.isAnonymous) ? (
            user.isAnonymous ? (
              "Limited tier"
            ) : premium ? (
              "Premium"
            ) : (
              "Free tier"
            )
          ) : (
            <Skeleton width={64} height={20} />
          )
        }
        leading={{ img: user.session.photoURL, Icon: AnonymousIcon }}
        sx={{
          "&:hover": {
            cursor: "default",
            bgcolor: "unset"
          },
          "& svg": {
            opacity: 1,
            color: "var(--surface-on-variant)"
          }
        }}
      />
      <ListItem
        line1Sx={line1Sx}
        line1={`Log ${user.isAnonymous ? "in" : "out"}`}
        onClick={() =>
          user.isAnonymous ? user.sign.in.google() : user.sign.out()
        }
      />
      <Collapse
        in={
          user.loaded &&
          (user.isAnonymous || user.syncedWithStripe) &&
          premium !== true
        }
      >
        <GenericListItem>
          <Box
            p={1}
            bgcolor="var(--primary-container)"
            borderRadius="var(--shape-sm)"
          >
            {user.isAnonymous ? (
              <Typography
                component="div"
                variant="titleSm"
                fontWeight={600}
                onClick={user.sign.in.google}
                sx={{ cursor: "pointer" }}
                color="var(--primary-on-container)"
              >
                Log in
              </Typography>
            ) : (
              <Typography
                component="a"
                target="_blank"
                href={upgradeUrl}
                variant="titleSm"
                fontWeight={600}
                sx={{ textDecoration: "none", cursor: "pointer" }}
                color="var(--primary-on-container)"
              >
                Upgrade
              </Typography>
            )}
            <Typography
              mt={1}
              component="p"
              color="var(--secondary-color)"
              variant="bodyMd"
            >
              {user.isAnonymous
                ? "Premium features and free credits to run models"
                : `Unlimited access + run ${utilities
                    .formatNumber(data?.output ?? 0)
                    .toLowerCase()} models with no rate limits`}
            </Typography>
          </Box>
        </GenericListItem>
      </Collapse>
      <ListItem
        target="_blank"
        line1="Account"
        line1Sx={line1Sx}
        href={customerPortalUrl}
        disabled={customerPortalUrl === undefined}
        onClick={user.isAnonymous ? user.sign.in.google : undefined}
      />
    </>
  );
});

function ChangeTheme() {
  const { mode, setMode } = useColorScheme();
  const Icon =
    mode === "dark"
      ? DarkModeIcon
      : mode === "system"
      ? SystemModeIcon
      : LightModeIcon;

  return (
    <ListItem
      line1={`${mode} mode`}
      onClick={() =>
        setMode(
          mode === "light" ? "dark" : mode === "system" ? "light" : "system"
        )
      }
      trailing={{ Icon: () => <Icon sx={{ color: "var(--primary-color)" }} /> }}
    />
  );
}
