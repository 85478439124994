"use client";
import { Stack, Drawer, Box } from "@mui/material";

import { useStore, observer } from "../../service/mobx";
import { ListItem } from "../List/ListItem";
import Sessions from "./Session";
import Account from "./Account";
import Plan from "./Plan";
import Developers from "./Developers";
import { useWhiteLabelling } from "../Hooks";

function Navigation() {
  const { layout, device } = useStore();
  const isWhiteLabelled = useWhiteLabelling();
  const loading = device.measured === false;

  return (
    <Drawer
      variant={device.isPhone ? "temporary" : "permanent"}
      open={layout.navOpen}
      onClose={() => layout.set.navOpen(false)}
      sx={theme => ({
        displayPrint: "none",
        display: loading || isWhiteLabelled ? "none" : undefined,
        width: layout.navOpen ? 280 : device.isPhone ? 0 : 72,
        transition: theme.transitions.create("width", {
          easing: `var(--motion-easing-emphasized${
            device.isPhone ? "" : layout.navOpen ? "Decelerate" : "Accelerate"
          })`,
          duration: layout.navOpen ? (device.isPhone ? 500 : 400) : 200
        }),
        overflowX: "hidden",
        zIndex: "calc( var(--zIndex-appBar) - 1 )",
        "& .MuiDrawer-paper": {
          px: 1,
          border: "none",
          borderRight: 2,
          borderColor: "var(--surface-dim)",
          width:
            device.isPhone === false && layout.navOpen === false ? 72 : 280,
          overflowX: "hidden",
          boxSizing: "border-box",
          backgroundImage: "none",
          bgcolor: "var(--surface-dim)",
          zIndex: "calc( var(--zIndex-appBar) - 1 )",
          transition: theme.transitions.create("width", {
            easing: `var(--motion-easing-emphasized${
              device.isPhone ? "" : layout.navOpen ? "Decelerate" : "Accelerate"
            })`,
            duration: layout.navOpen ? (device.isPhone ? 500 : 400) : 200
          })
        }
      })}
    >
      <Stack
        justifyContent="space-between"
        height="inherit"
        pb={2}
        sx={{
          "& svg, .MuiTypography-root": {
            color: "var(--secondary-color)"
          }
        }}
      >
        <Box sx={{ pt: 9 }}>
          <Sessions />
        </Box>
        <Stack useFlexGap spacing={2}>
          <Developers />
          <Plan />
          <Account />
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default observer(Navigation);

export const NavListItem = ({ sx, ...props }) => (
  <ListItem sx={{ borderRadius: "var(--shape-md-round)", ...sx }} {...props} />
);
