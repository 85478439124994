"use client";
import { Box } from "@mui/material";
import { ExpandMoreRounded as MoreIcon } from "@mui/icons-material";

import { useStore, observer } from "../../../service/mobx";
import { AnonymousIcon } from "../../Icon/Anonymous";
import { NavListItem } from "../index";
import Menu from "./Menu";
import Skeleton from "../../Skeleton";

function Account() {
  const { layout, menu, user } = useStore();

  return user.loaded ? (
    <NavListItem
      line1={user.isAnonymous ? "Not logged in" : user.session.displayName}
      line2={user.session.email}
      leading={{ img: user.session.photoURL, Icon: AnonymousIcon }}
      trailing={{ Icon: MoreIcon }}
      sx={{
        p: user.isAnonymous ? undefined : 0.5,
        py: 0,
        "& span": {
          whiteSpace: "nowrap",
          px: layout.navOpen ? undefined : 1.5,
          transition: "all 200ms var(--motion-easing-emphasized)"
        },
        "& .MuiListItemAvatar-root": {
          pl: 0
        },
        "& > :nth-child(3) svg": {
          transform: menu.open ? "rotate(180deg)" : undefined,
          transition: "transform 200ms var(--motion-easing-standard)"
        },
        "& .MuiAvatar-root": {
          width: 48,
          height: 48,
          borderRadius: layout.navOpen ? "var(--shape-md-round)" : "50%",
          transition: "border-radius 200ms var(--motion-easing-emphasized)"
        }
      }}
      onClick={event => {
        const navWasClosed = layout.navOpen === false;

        layout.set.navOpen(true);
        menu.configure({
          anchor: event.currentTarget,
          Component: Menu,
          sx: {
            menuList: {
              py: 0
            },
            paper: {
              width: 248,
              maxHeight: "unset",
              borderRadius: "var(--shape-sm)"
            }
          },
          onClose() {
            if (navWasClosed) {
              layout.set.navOpen(false);
            }

            menu.reset();
          },
          options: {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "center"
            }
          }
        });
      }}
    />
  ) : (
    <Box
      height={72}
      p={layout.navOpen ? 1 : undefined}
      pl={layout.navOpen ? undefined : 0.5}
      pt={layout.navOpen ? undefined : 1.5}
    >
      <Skeleton
        width={layout.navOpen ? "100%" : 48}
        height={layout.navOpen ? "100%" : 48}
        borderRadius={layout.navOpen ? "md-round" : "round"}
      />
    </Box>
  );
}

export default observer(Account);
